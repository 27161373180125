import React, { useRef, useEffect, useState, Fragment } from 'react'
import ReactDOM from 'react-dom'
import { useSelector, useDispatch } from 'react-redux'
import { isEqual } from 'lodash'
import moment from 'moment'
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'

import {
  closeFilterSideBarView,
  openFilterSideBarView,
  openSidebarView,
  setZoomLevel,
} from '../../redux/view/viewActions'
import selectSearchableData from '../../redux/selectors/selectSearchableData'
import selectPortPinData from '../../redux/selectors/selectPortPinData'
import selectBrandPinData from '../../redux/selectors/selectBrandPinData'
import selectRegionPinData from '../../redux/selectors/selectRegionPinData'
import selectZoomControlDetails from '../../redux/selectors/selectZoomControlDetails'
import selectRegionZoomInitialBoundingBox from '../../redux/selectors/selectRegionZoomInitialBoundingBox'

import {
  ZoomLevel,
  PinTypeName,
  DeviceType,
  MAPBOX_ACCESS_TOKEN,
} from '../../configuration/constants'
import {
  selectPinFilters,
  selectValidPins,
} from '../../redux/filters/filtersSelectors'
import {
  setShipDeploymentsFilter,
  togglePinFilter,
} from '../../redux/filters/filtersActions'

import MapSearch from '../../components/MapSearch/MapSearch'
import PinFilters from '../../components/PinFilters/PinFilters'
import BackToUpperView from '../../components/BackToUpperView/BackToUpperView'
import MapZoomControl from '../../components/MapZoomControl/MapZoomControl'
import {
  WorldHomePortMarker,
  WorldRegionMarker,
  RegionHomePortMarker,
  RegionPortOfCallMarker,
  PortBrandMarker,
} from '../../components/Markers/Markers'

import {
  getBoundingBoxFromPins,
  fitBoundsOptionsMobile,
  fitBoundsOptionsDesktop,
  MAPBOX_ZOOM_STEP,
} from '../../utilities/mapbox'
import { getMarkerFlipStatusFromBoundingBoxes } from '../../utilities/markerPlacement'

import 'mapbox-gl/dist/mapbox-gl.css'
import './MapContainer.scss'
import Drawer from '../../components/Drawer/Drawer'
import { selectFilterSidebarViewIsOpen } from '../../redux/view/viewSelectors'
import PinFiltersExtended from '../../components/PinFiltersExtended/PinFiltersExtended'

import filterIcon from '../../assets/icons/filter.svg'
import blackFilterIcon from '../../assets/icons/filter-black.svg'

/**
 * statically assign token to mapbox before creating new instance
 */
mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN

const fitBoundsOptions = {
  [DeviceType.MOBILE]: fitBoundsOptionsMobile,
  [DeviceType.DESKTOP]: fitBoundsOptionsDesktop,
}

const latLngPadding = {
  [ZoomLevel.WORLD]: 0,
  [ZoomLevel.REGION]: 0,
  [ZoomLevel.PORT]: 0.028,
}

const deviceType = window.innerWidth > 880 ? 'desktop' : 'mobile'

const MapContainer = () => {
  const dispatch = useDispatch()
  const mapContainer = useRef(null)
  const map = useRef(null)

  const [lng, setLng] = useState(-65)
  const [lat, setLat] = useState(45)
  const [mapZoom, setMapZoom] = useState(1)
  const [currentSearchTerm, setCurrentSearchTerm] = useState('')
  const [currentBoundingBox, setCurrentBoundingBox] = useState(null)

  const [markers, setMarkers] = useState([])
  const [markerShortCodes, setMarkerShortCodes] = useState(new Set())
  const [flippedShortCodes, setFlippedShortCodes] = useState(new Set())

  const [pinFilters, setPinFilters] = useState({
    brands: [],
    dateRange: null, // 30, 60, or 90 days
    year: null, // YYYY
    month: null, // MM
    // todo: search bar value.
  })
  const regionPins = useSelector(selectRegionPinData)
  const portPins = useSelector(selectPortPinData)
  const brandPins = useSelector(selectBrandPinData)
  const searchableData = useSelector(selectSearchableData)
  const zoomControlData = useSelector(selectZoomControlDetails)
  const validPinFilters = useSelector(selectValidPins)
  const selectedPinFilters = useSelector(selectPinFilters)
  const pickedPinsInRegion = useSelector(selectRegionZoomInitialBoundingBox)

  const filterSideBarViewIsOpen = useSelector(selectFilterSidebarViewIsOpen)

  const relevantPinsByZoomLevel = {
    [ZoomLevel.WORLD]: [...regionPins, ...portPins],
    [ZoomLevel.REGION]: portPins,
    [ZoomLevel.PORT]: brandPins,
  }

  const allPins = relevantPinsByZoomLevel[zoomControlData.zoomLevel]
  const options = fitBoundsOptions[deviceType][zoomControlData.zoomLevel]
  const pad = latLngPadding[zoomControlData.zoomLevel]

  const updatePinFilters = (zoomLevel) => (type) => {
    dispatch(togglePinFilter(zoomLevel, type))
  }

  const filteredSearchData = (() => {
    if (currentSearchTerm === '') {
      return []
    }
    const searchTerm = currentSearchTerm.toLowerCase()
    return searchableData.filter((data) =>
      data.name.toLowerCase().includes(searchTerm)
    )
  })()

  const filteredPins = [...portPins, ...brandPins, ...regionPins].filter(
    (pin) => {
      const isValid = selectedPinFilters.includes(pin.type)

      if (!isValid) {
        return false
      }

      if (
        pinFilters?.brands?.length &&
        !pin?.brands?.some((pinBrand) =>
          pinFilters?.brands.some((brand) => brand === pinBrand)
        )
      ) {
        return false
      }

      if (pinFilters.dateRange) {
        const targetDate = moment().add(pinFilters.dateRange, 'days')

        const hasDateInRange = pin?.dates?.some((date) =>
          targetDate.isSameOrAfter(date)
        )

        if (!hasDateInRange) {
          return false
        }
      }

      if (pinFilters.year) {
        const hasMatchingYear = pin?.dates?.some(
          (date) => moment(date).format('YYYY') === pinFilters.year
        )

        if (!hasMatchingYear) {
          return false
        }

        if (pinFilters.month) {
          const hasMatchingMonth = pin?.dates?.some((date) => {
            return (
              moment(date).format('YYYY') === pinFilters.year &&
              moment(date).format('MMM') === pinFilters.month
            )
          })
          if (!hasMatchingMonth) {
            return false
          }
        }
      }
      return isValid
    }
  )

  function handlePortZoom(shortCode) {
    dispatch(setZoomLevel(ZoomLevel.PORT, shortCode, true))
    setFlippedShortCodes(new Set())
    setMarkerShortCodes(new Set())
  }

  function handleRegionZoom(shortCode) {
    dispatch(setZoomLevel(ZoomLevel.REGION, shortCode, true))
    setFlippedShortCodes(new Set())
    setMarkerShortCodes(new Set())
  }

  function handlePortDetails(shortCode, pinType) {
    dispatch(openSidebarView('port', shortCode, pinType))
  }

  function handleShipDetails(shortCode, portCode) {
    dispatch(
      setShipDeploymentsFilter({
        portOfCall: [portCode],
      })
    )

    dispatch(openSidebarView('ship', shortCode))
  }

  function handleFilterDrawerToggle() {
    if (filterSideBarViewIsOpen) {
      dispatch(closeFilterSideBarView())
    } else {
      dispatch(openFilterSideBarView())
    }
  }

  const MarkerConfig = {
    [ZoomLevel.WORLD]: {
      [PinTypeName.REGION]: {
        Marker: WorldRegionMarker,
        onClick: handleRegionZoom,
      },
      [PinTypeName.HOME_PORT]: {
        Marker: WorldHomePortMarker,
        onClick: handlePortZoom,
        transparent: false,
      },
    },
    [ZoomLevel.REGION]: {
      [PinTypeName.HOME_PORT]: {
        Marker: RegionHomePortMarker,
        onClick: handlePortZoom,
        onDetailsClick: handlePortDetails,
      },
      [PinTypeName.PORT_OF_CALL]: {
        Marker: RegionPortOfCallMarker,
        transparent: false,
        onDetailsClick: handlePortDetails,
      },
    },
    [ZoomLevel.PORT]: {
      [PinTypeName.BRAND_CELEBRITY]: {
        Marker: PortBrandMarker,
        onShipClick: handleShipDetails,
      },
      [PinTypeName.BRAND_ROYAL_CARRIBEAN]: {
        Marker: PortBrandMarker,
        onShipClick: handleShipDetails,
      },
    },
  }

  // Component mount
  useEffect(() => {
    map.current = new mapboxgl.Map(
      {
        container: mapContainer.current,
        style: 'mapbox://styles/verb-mapbox/cksyza3kl0ukx17pkyvv2ncm8',
        center: [lng, lat],
        zoom: mapZoom,
        navigation: true,
        interactive: deviceType === DeviceType.MOBILE,
        bounds: getBoundingBoxFromPins(allPins, pad),
      },
      { navigation: true }
    )

    map.current.addControl(new mapboxgl.NavigationControl())
    map.current.dragPan.enable()
    map.current.dragRotate.disable()
    map.current.touchZoomRotate.disableRotation()

    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4))
      setLat(map.current.getCenter().lat.toFixed(4))
      setMapZoom(map.current.getZoom().toFixed(2))
    })
  }, [])

  const [shouldFlipLabels, setShouldFlipLabels] = useState(false)
  const [waitingForMapIdle, setWaitingForMapIdle] = useState(true)

  function handleMarkerLabelFlipping() {
    const newFlippedShortCodes = new Set()
    const markerLabels = document.getElementsByClassName('marker-label')
    const labelsArray = [...markerLabels]
    const boundingBoxes = labelsArray.map((label) =>
      label.getBoundingClientRect()
    )
    const shouldFlipArray = getMarkerFlipStatusFromBoundingBoxes(boundingBoxes)

    labelsArray.forEach((element, index) => {
      const marker = element.parentElement
      if (shouldFlipArray[index]) {
        marker.classList.add('flipped')
        newFlippedShortCodes.add(marker.getAttribute('shortCode'))
      }
      element.classList.remove('transparent')
    })
    setFlippedShortCodes(newFlippedShortCodes)
    setShouldFlipLabels(false)
  }

  const renderAndInsertMarkers = () => {
    const newMarkers = []
    const updatedMarkerShortCodes = new Set()

    markers.forEach((oldMarker) => {
      oldMarker.remove()
    })

    filteredPins.forEach((pin) => {
      const previouslyVisible = markerShortCodes.has(pin.shortCode)
      const additionalProps = {
        transparent: !previouslyVisible,
        flipped: flippedShortCodes.has(pin.shortCode),
        conflicts: pin.conflicts,
      }
      const { Marker, ...handlers } =
        MarkerConfig[zoomControlData.zoomLevel][pin.type]
      const markerNode = document.createElement('div')
      markerNode.classList.add(pin.type)

      // create a Dyamic marker
      ReactDOM.render(
        <Marker {...pin} {...additionalProps} {...handlers} />,
        markerNode
      )

      const newMarker = new mapboxgl.Marker(markerNode, { anchor: 'top' })
        .setLngLat([pin.lng, pin.lat])
        .addTo(map.current)

      newMarkers.push(newMarker)
      updatedMarkerShortCodes.add(pin.shortCode)
    })

    setMarkerShortCodes(updatedMarkerShortCodes)
    setMarkers(newMarkers)
    setShouldFlipLabels(true)
  }

  // New pins
  useEffect(() => {
    renderAndInsertMarkers()
  }, [selectedPinFilters, regionPins, portPins, pinFilters])

  useEffect(() => {
    if (shouldFlipLabels) {
      mapContainer.current.classList.add('flipping-pin-labels')
      if (waitingForMapIdle) {
        setWaitingForMapIdle(false)
        map.current.once('idle', () => {
          setTimeout(handleMarkerLabelFlipping, 0)
        })
      } else {
        setTimeout(handleMarkerLabelFlipping, 0)
      }
    } else {
      mapContainer.current.classList.remove('flipping-pin-labels')
    }
  }, [shouldFlipLabels])

  useEffect(() => {
    // map idle only fires on certain conditions (like zoom or pan) so this should only be used when appropriate
    setWaitingForMapIdle(true)
  }, [zoomControlData.zoomLevel])

  // Handle zoom to new set of pins
  useEffect(() => {
    const isRegion = zoomControlData.zoomLevel === ZoomLevel.REGION
    const pinsForView = isRegion ? pickedPinsInRegion : allPins
    const newBounds = getBoundingBoxFromPins(pinsForView, pad)

    if (!isEqual(currentBoundingBox, newBounds)) {
      setCurrentBoundingBox(newBounds)
      map.current.setMaxZoom(10)
      map.current.fitBounds(newBounds, options)
      map.current.setMaxZoom(22)
    }
  }, [zoomControlData.zoomLevel, portPins, brandPins, regionPins])

  const handleSearchResult = (type, shortCode) => {
    dispatch(closeFilterSideBarView())
    switch (type) {
      case 'ship':
        return dispatch(openSidebarView('ship', shortCode))
      case 'port':
        return dispatch(setZoomLevel(ZoomLevel.PORT, shortCode, false))
      case 'region':
        return dispatch(setZoomLevel(ZoomLevel.REGION, shortCode, false))
      default:
        break
    }
    return null
  }

  const onManualZoom = (direction) => {
    const step = direction === 'in' ? MAPBOX_ZOOM_STEP : -MAPBOX_ZOOM_STEP
    map.current.zoomTo(map.current.getZoom() + step, {
      duration: 150,
    })
  }

  return (
    <Fragment>
      <div className="map-container-wrapper">
        <div
          ref={mapContainer}
          className="map-container"
          data-testid="map-container"
        />
        <div className="gradient-bg-overlay" />
      </div>
      <div
        className={`map-controls-wrapper view-${
          ZoomLevel[zoomControlData.zoomLevel]
        }`}
      >
        <div className="map-controls">
          {(zoomControlData.zoomLevel === ZoomLevel.WORLD ||
            zoomControlData.zoomLevel === ZoomLevel.REGION) && (
            <div className="filter-trigger-container">
              <PinFilters
                pinTypes={validPinFilters}
                selectedPins={selectedPinFilters}
                updateFilters={updatePinFilters(zoomControlData.zoomLevel)}
              />
              <div className="component-filters">
                <button
                  onClick={handleFilterDrawerToggle}
                  className="filter-button"
                >
                  <img src={filterIcon} alt="filter-icon"></img>
                  <span>Filters</span>
                </button>
              </div>
            </div>
          )}
          {/* enable two controls move together */}
          <div className="vertical-stack-controls">
            <BackToUpperView
              zoomLevel={zoomControlData.zoomLevel}
              viewName={zoomControlData.locationLabel}
              backButtonLabel={zoomControlData.backToLabel}
              backToUpperViewHandler={() => {
                dispatch(
                  setZoomLevel(
                    zoomControlData.upperZoomLevel,
                    zoomControlData.upperZoomResource
                  )
                )
                setFlippedShortCodes(new Set())
                setMarkerShortCodes(new Set())
              }}
            />
            <MapZoomControl
              zoomInHandler={() => onManualZoom('in')}
              zoomOutHandler={() => onManualZoom('out')}
              disabled={false}
            />
          </div>
        </div>
      </div>
      <Drawer
        isOpen={filterSideBarViewIsOpen}
        closeHandler={handleFilterDrawerToggle}
        isRightSide
        hideDefaultCloseButton
      >
        <div className="advance-pin-filters-drawer">
          <div className="advance-pin-filters-header">
            <div className="icon-container">
              <img src={blackFilterIcon} alt="filter-icon" />
              <p>Filters</p>
            </div>
            <button onClick={handleFilterDrawerToggle}>X Close</button>
          </div>
          <MapSearch
            searchTerm={currentSearchTerm}
            updateSearchTerm={setCurrentSearchTerm}
            searchResults={filteredSearchData}
            handleSelection={handleSearchResult}
          />
          <PinFiltersExtended
            toggleActiveBrands={(code) => {
              const isActive = pinFilters.brands.some((brand) => brand === code)

              setPinFilters({
                ...pinFilters,
                brands: isActive
                  ? pinFilters.brands.filter((brand) => brand !== code)
                  : [...pinFilters.brands, code],
              })
            }}
            activeBrands={pinFilters.brands}
            toggleRange={(dayCount) => {
              setPinFilters({
                ...pinFilters,
                dateRange: pinFilters.dateRange === dayCount ? null : dayCount,
                year: null,
                month: null,
              })
            }}
            activeDateRange={pinFilters.dateRange}
            activeYear={pinFilters.year}
            toggleYear={(year) => {
              const isRemovingYear = pinFilters.year === year
              setPinFilters({
                ...pinFilters,
                year: isRemovingYear ? null : year,
                month: isRemovingYear ? null : pinFilters.month,
                dateRange: null,
              })
            }}
            activeMonth={pinFilters.month}
            toggleMonth={(month) => {
              setPinFilters({
                ...pinFilters,
                month: pinFilters.month === month ? null : month,
                year: pinFilters.year
                  ? pinFilters.year
                  : moment().format('YYYY'),
              })
            }}
          />
        </div>
      </Drawer>
    </Fragment>
  )
}

export default MapContainer
