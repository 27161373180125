import { createSelector } from 'reselect'
import { MONTH_FILTER_OPTIONS } from '../../configuration/constants'
import { selectSidebarViewResource } from '../view/viewSelectors'
import { selectSailings, selectPackages } from '../sailings/sailingsSelectors'
import { selectRegionsIndexed } from '../regions/regionsSelectors'
import { selectPortsIndexed } from '../ports/portsSelectors'

const selectShipViewDeploymentsFilterOptions = createSelector(
  selectSidebarViewResource,
  selectSailings,
  selectPackages,
  selectRegionsIndexed,
  selectPortsIndexed,
  (shipCode, sailings, packages, regions, ports) => {
    // Determine regions
    const regionCodes = new Set()
    const portOfCallCodes = new Set()

    Object.values(packages).forEach((packageInfo) => {
      if (packageInfo.shipCode === shipCode) {
        regionCodes.add(packageInfo.destinationCode)
        packageInfo.ports.forEach((portCode) => portOfCallCodes.add(portCode))
      }
    })

    const compareLabels = (a, b) => (a.label < b.label ? -1 : 1)
    const regionOptions = [...regionCodes]
      .map((regionCode) => {
        return {
          value: regionCode,
          label: regions[regionCode].name,
        }
      })
      .sort(compareLabels)
    const portOfCallOptions = [...portOfCallCodes]
      .map((portCode) => {
        if (ports[portCode]) {
          return {
            value: portCode,
            label: ports[portCode].name,
          }
        }
        return null
      })
      .filter((x) => !!x)
      .sort(compareLabels)

    return [
      {
        title: 'Sailing Dates',
        options: MONTH_FILTER_OPTIONS,
        key: 'month',
        type: 'date',
      },
      {
        title: 'Destination',
        options: regionOptions,
        key: 'region',
        type: 'destination',
      },
      {
        title: 'Port of Call',
        options: portOfCallOptions,
        key: 'portOfCall',
        type: 'port',
      },
    ]
  }
)

export default selectShipViewDeploymentsFilterOptions
