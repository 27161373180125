import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './RegionContainer.scss'

import AlertNotice from '../../components/AlertNotice/AlertNotice'
import ClickthroughDetails from '../../components/ClickthroughDetails/ClickthroughDetails'
import ClickthroughHeader from '../../components/ClickthroughHeader/ClickthroughHeader'
import DeploymentTable from '../../components/DeploymentTable/DeploymentTable'
import regionPin from '../../assets/icons/region-pin-shadow.svg'
import regionIcon from '../../assets/icons/menu-regions-icon.svg'
import ShowMoreToggle from '../../components/ShowMoreToggle/ShowMoreToggle'

import selectRegionViewContent from '../../redux/selectors/selectRegionViewContent'
import selectRegionViewDeployments from '../../redux/selectors/selectRegionViewDeployments'
import selectRegionViewDeploymentsFilterOptions from '../../redux/selectors/selectRegionViewDeploymentsFilterOptions'
import { selectFilterableYears } from '../../redux/sailings/sailingsSelectors'
import { selectRegionDeploymentsFilter } from '../../redux/filters/filtersSelectors'
import { setRegionDeploymentsFilter } from '../../redux/filters/filtersActions'
import { getRegionDefaultFilterOptions } from '../../redux/regions/regionUtils'

function generateDetailsComponentParam(content) {
  return {
    imageSrc: content.imageSrc,
    sections: [
      {
        type: 'info',
        label: 'Visiting Ships',
        value: content.visitingShips,
      },
      {
        type: 'info',
        label: 'Ports of Call',
        value: content.portsOfCall,
      },
      {
        type: 'bio',
        label: 'Destination Bio',
        value: content.bio,
      },
    ],
  }
}

const RegionContainer = () => {
  const dispatch = useDispatch()
  const content = useSelector(selectRegionViewContent)
  const deployments = useSelector(selectRegionViewDeployments)
  const [showDestinationInformation, setShowDestinationInformation] =
    useState(false)
  const years = useSelector(selectFilterableYears)
  const filter = useSelector(selectRegionDeploymentsFilter)
  const filterOptions = useSelector(selectRegionViewDeploymentsFilterOptions)
  const { alertMessage } = content

  useEffect(() => {
    dispatch(setRegionDeploymentsFilter(getRegionDefaultFilterOptions()))
  }, [])

  const clickthroughHeader = (
    <ClickthroughHeader
      pin={<img src={regionPin} alt="" />}
      title={content.name}
    />
  )

  const filterChangeHandler = (updates) => {
    dispatch(setRegionDeploymentsFilter(updates))
  }

  return (
    <div className="region-container">
      <header className="mobile">
        <div className="title-row">
          <img src={regionIcon} alt="" />
          <div className="title">Regions</div>
        </div>
      </header>
      <header className="desktop">{clickthroughHeader}</header>
      <main>
        <div className="mobile">{clickthroughHeader}</div>
        {alertMessage && (
          <div className="alert-wrapper">
            <AlertNotice {...alertMessage} />
          </div>
        )}

        <div className="details-section">
          <div className="mobile show-additional-information">
            <div className="title">Destination Information</div>
            <ShowMoreToggle
              show={showDestinationInformation}
              onClickHandler={() =>
                setShowDestinationInformation(!showDestinationInformation)
              }
            />
          </div>
          <div
            className={`additional-information ${
              showDestinationInformation ? '' : 'hidden'
            }`}
          >
            <ClickthroughDetails {...generateDetailsComponentParam(content)} />
          </div>
        </div>

        <DeploymentTable
          deployments={deployments}
          years={years}
          filter={filter}
          filterSections={filterOptions}
          updateFilters={filterChangeHandler}
          icon={regionIcon}
          isShipVariant
        />
      </main>
    </div>
  )
}

export default RegionContainer
