import React, { useRef, useState } from 'react'
import { string, number, arrayOf, bool, shape, func } from 'prop-types'
import './Markers.scss'
import shipShortIcon from '../../assets/icons/ship-short-icon.png'
import zoomIcon from '../../assets/icons/zoom-icon.png'
import { BrandCode, PinTypeName } from '../../configuration/constants'
import rcclBrand from '../../assets/icons/rccl-content-tag.svg'
import celebrityBrand from '../../assets/icons/celebrity-content-tag.svg'

const getBrandIcon = (brandCode) => {
  switch (brandCode) {
    case BrandCode.ROYAL_CARRIBBEAN:
      return rcclBrand
    case BrandCode.CELEBRITY:
      return celebrityBrand
    default:
      return null
  }
}

export const WorldRegionMarker = ({ label, shortCode, flipped, onClick }) => {
  return (
    <div
      className={`marker world-view region ${flipped ? 'flipped' : ''}`}
      onClick={() => onClick(shortCode)}
    >
      <div className="marker-icon-wrapper">
        <div className="marker-icon" />
      </div>
      <div className="marker-label">{label}</div>
    </div>
  )
}

export const WorldHomePortMarker = ({
  label,
  regions,
  shortCode,
  transparent,
  flipped,
  onClick,
}) => {
  if (regions.length > 1) {
    return (
      <div className={`marker world-view homeport ${flipped ? 'flipped' : ''}`}>
        <div className="marker-icon-wrapper" onClick={() => onClick(shortCode)}>
          <div className="marker-icon" />
        </div>
        <div className="marker-card-wrapper">
          <div className="marker-card">
            <div className="header">{label}</div>
            <div className="title">Regions</div>
            <div className="regions">
              {regions &&
                regions.map((region, index) => {
                  return (
                    <button
                      type="button"
                      key={index}
                      className="basic-link chevron-right"
                    >
                      {region}
                    </button>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className="marker world-view homeport"
        onClick={() => onClick(shortCode)}
      >
        <div className="marker-icon-wrapper">
          <div className="marker-icon" />
        </div>
        <div className={`marker-label ${transparent ? 'transparent' : ''}`}>
          {label}
        </div>
      </div>
    )
  }
}

export const RegionHomePortMarker = ({
  label,
  itineraryCount,
  ships,
  shortCode,
  onClick,
  flipped,
  onDetailsClick,
}) => {
  const ref = useRef(null)
  const [top, setTop] = useState(0)
  const positionCard = () => {
    if (ref?.current?.getBoundingClientRect()?.bottom >= window.innerHeight) {
      setTop(
        window.innerHeight - ref?.current?.getBoundingClientRect()?.bottom - 36
      )
    } else {
      setTop(0)
    }
  }

  return (
    <div
      className={`marker region-view homeport fades-in ${
        flipped ? 'flipped' : ''
      }`}
      onClick={() => onClick(shortCode)}
      onMouseEnter={positionCard}
      onMouseLeave={() => setTop(-35)}
    >
      <div className="marker-icon-wrapper">
        <div className="marker-icon" />
      </div>
      <div className="marker-label">{label}</div>
      <div
        className="marker-card-wrapper"
        style={{
          transform: `translate(0, ${top}px)`,
        }}
      >
        <div className="marker-card" ref={ref}>
          <div className="header">
            {label}
            <div onClick={() => onClick(shortCode)}>
              <img src={zoomIcon} alt="" />
            </div>
          </div>
          <div className="group">
            <div className="title">No. of Itineraries</div>
            <div>{itineraryCount}</div>
          </div>
          <div className="group">
            <div className="title">
              <span>Current Ships</span>
              <span className="count">{ships ? ships.length : 0}</span>
            </div>
            <div className="ships">
              {ships &&
                ships.map((ship, index) => {
                  return (
                    <div className="ship" key={index}>
                      <img src={getBrandIcon(ship.brandCode)} alt="" />
                      <div>{ship.name}</div>
                    </div>
                  )
                })}
            </div>
          </div>
          <div className="direction">
            <button
              type="button"
              className="basic-link"
              onClick={() => onDetailsClick(shortCode, PinTypeName.HOME_PORT)}
            >
              Deployment Details
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export const RegionPortOfCallMarker = ({
  label,
  itineraryCount,
  shortCode,
  ships,
  flipped,
  onDetailsClick,
}) => {
  return (
    <div
      className={`marker region-view portofcall fades-in ${
        flipped ? 'flipped' : ''
      }`}
    >
      <div className="marker-icon-wrapper">
        <div className="marker-icon" />
      </div>
      <div className="marker-label transparent">{label}</div>
      <div className="marker-card-wrapper">
        <div className="marker-card">
          <div className="header">{label}</div>
          <div className="group">
            <div className="title">No. of Itineraries</div>
            <div>{itineraryCount}</div>
          </div>
          <div className="group">
            <div className="title">
              <span>Current Ships</span>
              <span className="count">{ships ? ships.length : 0}</span>
            </div>
            <div className="ships">
              {ships &&
                ships.map((ship, index) => {
                  return (
                    <div className="ship" key={index}>
                      <img src={getBrandIcon(ship.brandCode)} alt="" />
                      <div>{ship.name}</div>
                    </div>
                  )
                })}
            </div>
          </div>
          <div className="direction">
            <button
              type="button"
              className="basic-link"
              onClick={() =>
                onDetailsClick(shortCode, PinTypeName.PORT_OF_CALL)
              }
            >
              Deployment Details
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export const PortBrandMarker = ({
  ships,
  brandCode,
  onShipClick,
  port,
  conflicts,
}) => {
  return (
    <div
      className={`marker brand brand-code-${brandCode} fades-in ${
        conflicts ? 'conflicts' : ''
      }`}
    >
      <div className="marker-icon-wrapper">
        <div className="marker-icon">
          <div className="count">{ships ? ships.length : 0}</div>
        </div>
      </div>

      <div className="marker-card-wrapper">
        <div className="marker-card">
          <div className="ships">
            {ships.map((ship) => {
              return (
                <div key={ship.name} className="ship">
                  <button
                    type="button"
                    className="basic-link"
                    onClick={() => onShipClick(ship.shipCode, port?.portCode)}
                  >
                    <img src={shipShortIcon} alt="" />
                    <span className="name">{ship.name}</span>
                  </button>
                  <div className="sails-to">
                    <div className="title">Sails to</div>
                    <div>{ship.sailsTo.join(', ')}</div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="note">* Ship destination is approximate</div>
        </div>
      </div>
    </div>
  )
}

WorldRegionMarker.propTypes = {
  label: string.isRequired,
  shortCode: string.isRequired,
  flipped: bool.isRequired,
  onClick: func.isRequired,
}

WorldHomePortMarker.propTypes = {
  label: string.isRequired,
  regions: arrayOf(string).isRequired,
  transparent: bool.isRequired,
  flipped: bool.isRequired,
  shortCode: string.isRequired,
  onClick: func.isRequired,
}

RegionHomePortMarker.propTypes = {
  label: string.isRequired,
  itineraryCount: number.isRequired,
  ships: arrayOf(
    shape({ brandCode: string.isRequired, name: string.isRequired })
  ).isRequired,
  shortCode: string.isRequired,
  flipped: bool.isRequired,
  onClick: func.isRequired,
  onDetailsClick: func.isRequired,
}

RegionPortOfCallMarker.propTypes = {
  label: string.isRequired,
  shortCode: string.isRequired,
  flipped: bool.isRequired,
  itineraryCount: number.isRequired,
  ships: arrayOf(
    shape({ brandCode: string.isRequired, name: string.isRequired })
  ).isRequired,
  onDetailsClick: func.isRequired,
}

PortBrandMarker.propTypes = {
  ships: arrayOf(
    shape({ name: string.isRequired, sailsTo: arrayOf(string).isRequired })
  ).isRequired,
  brandCode: string.isRequired,
  onShipClick: func.isRequired,
  port: shape({
    name: string,
    portCode: string,
  }).isRequired,
  conflicts: bool.isRequired,
}
