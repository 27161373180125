import { actionTypes } from './viewActions'
import { ZoomLevel } from '../../configuration/constants'

const initialState = {
  mobileMenuIsOpen: false,
  sidebarView: null,
  sidebarViewResource: null,
  pinType: null, // home or call of port clicked?
  zoomLevel: 'WORLD',
  zoomResource: null,
  upperZoomLevel: 'WORLD',
  upperZoomResource: null,
  filterDrawerIsOpen: false,
}

function viewReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.OPEN_MOBILE_MENU:
      return { ...state, mobileMenuIsOpen: true }
    case actionTypes.CLOSE_MOBILE_MENU:
      return { ...state, mobileMenuIsOpen: false }
    case actionTypes.OPEN_SIDEBAR_VIEW:
      return {
        ...state,
        sidebarView: action.payload.view,
        sidebarViewResource: action.payload.resourceKey,
        pinType: action.payload.pinType || null,
      }
    case actionTypes.CLOSE_SIDEBAR_VIEW:
      return {
        ...state,
        sidebarView: null,
        sidebarViewResource: null,
        pinType: null,
      }
    case actionTypes.SET_ZOOM_LEVEL:
      return {
        ...state,
        zoomLevel: action.payload.zoomLevel,
        zoomResource: action.payload.resourceKey,
        upperZoomLevel: action.payload.copyCurrentToUpper
          ? state.zoomLevel
          : ZoomLevel.WORLD,
        upperZoomResource: action.payload.copyCurrentToUpper
          ? state.zoomResource
          : null,
      }
    case actionTypes.OPEN_FILTER_SIDEBAR_VIEW:
      return {
        ...state,
        filterDrawerIsOpen: true,
      }
    case actionTypes.CLOSE_FILTER_SIDEBAR_VIEW:
      return {
        ...state,
        filterDrawerIsOpen: false,
      }
    default:
      return state
  }
}

export default viewReducer
