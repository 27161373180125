export const actionTypes = {
  OPEN_MOBILE_MENU: 'view/OPEN_MOBILE_MENU',
  CLOSE_MOBILE_MENU: 'view/CLOSE_MOBILE_MENU',
  OPEN_SIDEBAR_VIEW: 'view/OPEN_SIDEBAR_VIEW',
  CLOSE_SIDEBAR_VIEW: 'view/CLOSE_SIDEBAR_VIEW',
  SET_ZOOM_LEVEL: 'view/SET_ZOOM_LEVEL',
  OPEN_FILTER_SIDEBAR_VIEW: 'view/OPEN_FILTER_SIDEBAR_VIEW',
  CLOSE_FILTER_SIDEBAR_VIEW: 'view/CLOSE_FILTER_SIDEBAR_VIEW',
}

export const openMobileMenu = () => (dispatch) => {
  dispatch({ type: actionTypes.OPEN_MOBILE_MENU })
}

export const closeMobileMenu = () => (dispatch) => {
  dispatch({ type: actionTypes.CLOSE_MOBILE_MENU })
}

export const openSidebarView =
  (view, resourceKey, pinType = null) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.OPEN_SIDEBAR_VIEW,
      payload: { view, resourceKey, pinType },
    })
  }

export const closeSidebarView = () => (dispatch) => {
  dispatch({ type: actionTypes.CLOSE_SIDEBAR_VIEW })
}

export const openFilterSideBarView = () => (dispatch) => {
  dispatch({ type: actionTypes.OPEN_FILTER_SIDEBAR_VIEW })
}
export const closeFilterSideBarView = () => (dispatch) => {
  dispatch({ type: actionTypes.CLOSE_FILTER_SIDEBAR_VIEW })
}

export const setZoomLevel =
  (zoomLevel, resourceKey, copyCurrentToUpper = false) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_ZOOM_LEVEL,
      payload: { zoomLevel, resourceKey, copyCurrentToUpper },
    })
  }

export const zoomToAndCloseSidebar =
  (zoomLevel, resourceKey) => (dispatch, getState) => {
    const state = getState()
    if (state.view.mobileMenuIsOpen) {
      dispatch(closeMobileMenu())
    }
    dispatch(closeSidebarView())
    dispatch(setZoomLevel(zoomLevel, resourceKey))
  }
