import React, { useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './SideBar.scss'

import ItineraryAndAnnouncementsContainer from '../ItineraryAndAnnouncements/ItineraryAndAnnouncementsContainer'
import ArticleContainer from '../Article/ArticleContainer'
import DeploymentsContainer from '../Deployments/DeploymentsContainer'
import RegionContainer from '../Region/RegionContainer'
import RegionsContainer from '../Regions/RegionsContainer'
import ShipContainer from '../Ship/ShipContainer'
import ShipsContainer from '../Ships/ShipsContainer'
import PortContainer from '../Port/PortContainer'
import Drawer from '../../components/Drawer/Drawer'

import { ReactComponent as Logo } from '../../assets/icons/ship-locator-logo-with-text.svg'
import { ReactComponent as MenuAnnouncementsIcon } from '../../assets/icons/menu-announcements-icon.svg'
import { ReactComponent as MenuAllDeploymentsIcon } from '../../assets/icons/menu-all-deployments-icon.svg'
import { ReactComponent as MenuShipsIcon } from '../../assets/icons/menu-ships-icon.svg'
import { ReactComponent as MenuRegionsIcon } from '../../assets/icons/menu-regions-icon.svg'
import { ReactComponent as MenuExitIcon } from '../../assets/icons/menu-exit-icon.svg'

import { ReactComponent as DownTriangle } from '../../assets/icons/down-triangle.svg'

import SvgIcon from '../../components/SvgIcon/SvgIcon'

import {
  selectMobileMenuIsOpen,
  selectSidebarView,
} from '../../redux/view/viewSelectors'
import {
  openSidebarView,
  closeSidebarView,
  openMobileMenu,
  closeMobileMenu,
} from '../../redux/view/viewActions'
import { setShipDeploymentsFilter } from '../../redux/filters/filtersActions'
import { getShipDefaultFilterOptions } from '../../redux/ships/shipUtils'
/* changing to just "Announcements" temporarily as per Jira issue: RCL220-94
import { selectItineraryUpdatesReverseChronologicalArray } from '../../redux/itineraryUpdates/itineraryUpdatesSelectors'
 */

const SideBar = () => {
  const dispatch = useDispatch()

  const sidebarView = useSelector(selectSidebarView)
  const mobileMenuIsOpen = useSelector(selectMobileMenuIsOpen)

  /* changing to just "Announcements" temporarily as per Jira issue: RCL220-94
  const newItineraryUpdateCount =
    useSelector(selectItineraryUpdatesReverseChronologicalArray)?.filter(
      (update) => update.isNew
    )?.length || 0
   */

  const [isClosing, setIsClosing] = useState(false)

  const containerMap = {
    announcements: ItineraryAndAnnouncementsContainer,
    article: ArticleContainer,
    deployments: DeploymentsContainer,
    region: RegionContainer,
    regions: RegionsContainer,
    ship: ShipContainer,
    ships: ShipsContainer,
    port: PortContainer,
  }
  const VisibleContainer = containerMap[sidebarView]

  const swapView = (view, resourceKey = null) => {
    if (isClosing) {
      return
    }
    // ship filters are cleared in ShipContainer but we need to maintain this one until
    // the view changes
    dispatch(
      setShipDeploymentsFilter({
        ...getShipDefaultFilterOptions(),
      })
    )

    if (sidebarView === null) {
      dispatch(openSidebarView(view, resourceKey))
    } else {
      setIsClosing(true)
      setTimeout(() => {
        setIsClosing(false)
        if (view === sidebarView || view === null) {
          dispatch(closeSidebarView())
        } else {
          dispatch(openSidebarView(view, resourceKey))
        }
      }, 400)
    }
  }

  const handleMobileMenuToggle = () => {
    if (mobileMenuIsOpen) {
      dispatch(closeMobileMenu())
    } else {
      dispatch(openMobileMenu())
    }
  }

  return (
    <Fragment>
      <div id="side-bar" className={mobileMenuIsOpen ? 'open' : ''}>
        <header>
          <Logo className="logo" />
        </header>
        <main>
          <nav>
            <ul>
              <li>
                <div onClick={() => swapView('announcements')}>
                  <div className="icon-with-count-container">
                    <MenuAnnouncementsIcon />
                    {/* changing to "Announcements" temporarily as per Jira issue: RCL220-94
                    {newItineraryUpdateCount > 0 ? (
                      <div className="icon-count">
                        {newItineraryUpdateCount}
                      </div>
                    ) : null}
                    */}
                  </div>
                  {/* changing to "Announcements" temporarily as per Jira issue: RCL220-94
                  Itinerary Updates
                  <br />& Announcements
                  */}
                  Announcements
                </div>
              </li>

              <li>
                <div onClick={() => swapView('deployments')}>
                  <MenuAllDeploymentsIcon />
                  All Deployments
                </div>
              </li>

              <li>
                <div onClick={() => swapView('ships')}>
                  <MenuShipsIcon />
                  Ships
                </div>
              </li>

              <li>
                <div onClick={() => swapView('regions')}>
                  <MenuRegionsIcon />
                  Regions
                </div>
              </li>

              <li>
                <div
                  onClick={() => {
                    window.location.href = 'http://www.cruisingpower.com/'
                  }}
                  className="exit"
                >
                  <MenuExitIcon />
                  Exit Map
                </div>
              </li>
            </ul>
          </nav>
        </main>
        <footer>
          <a
            href="https://secure.cruisingpower.com/protected/global/legal.do"
            target="_blank"
            rel="noreferrer"
          >
            Legal Information
          </a>
          <div className="copyright">
            &copy; {new Date().getFullYear()} Royal Caribbean International
          </div>
        </footer>
        <button
          type="button"
          className="menu-toggle"
          onClick={handleMobileMenuToggle}
        >
          {mobileMenuIsOpen ? 'Hide Menu' : 'View Menu'}
          {sidebarView ? (
            <SvgIcon
              type="close-x"
              className="toggle-state"
              fill="#3F6BAB" // medium-blue
              width="11"
              viewBox="0 0 16 16"
              height="11"
              style={{ top: '1px' }}
            />
          ) : (
            <DownTriangle />
          )}
        </button>
      </div>

      <Drawer
        isOpen={!!sidebarView && !isClosing}
        closeHandler={() => swapView(null)}
      >
        {sidebarView ? <VisibleContainer swapView={swapView} /> : null}
      </Drawer>
    </Fragment>
  )
}
export default SideBar
