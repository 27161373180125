import React from 'react'
import { node, string, func } from 'prop-types'
import './ClickthroughHeader.scss'

import chevronDown from '../../assets/icons/chevron-down.svg'

const ClickthroughHeader = ({ icon, pin, title, shareClickHandler }) => {
  return (
    <div className="title-row">
      <div className="clickthrough-header">
        {icon ? <div className="icon desktop">{icon}</div> : ''}
        {pin ? <div className="pin">{pin}</div> : ''}
        <div className="title">{title}</div>
        {shareClickHandler && (
          <div className="share">
            <button
              type="button"
              className="basic-link"
              onClick={shareClickHandler}
            >
              Share Deployment Schedule
              <img src={chevronDown} alt="" />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

ClickthroughHeader.propTypes = {
  icon: node,
  pin: node,
  title: string.isRequired,
  shareClickHandler: func,
}

ClickthroughHeader.defaultProps = {
  icon: null,
  pin: null,
  shareClickHandler: null,
}

export default ClickthroughHeader
