import React from 'react'
import { func } from 'prop-types'
import './DeploymentsContainer.scss'

import { useDispatch, useSelector } from 'react-redux'
import { selectAllDeploymentsFilters } from '../../redux/filters/filtersSelectors'
import selectAllDeploymentsFilterOptions from '../../redux/selectors/selectAllDeploymentsFilterOptions'
import selectAllDeploymentsViewContent from '../../redux/selectors/selectAllDeploymentsViewContent'
import { setAllDeploymentsFilter } from '../../redux/filters/filtersActions'

import ExpandableCardLayout from '../../components/ExpandableCardLayout/ExpandableCardLayout'
import DeploymentCard from '../../components/DeploymentCard/DeploymentCard'
import DeploymentFilters from '../../components/DeploymentFilters/DeploymentFilters'

const DeploymentsContainer = ({ swapView }) => {
  const dispatch = useDispatch()
  const filteredShips = useSelector(selectAllDeploymentsViewContent)
  const filters = useSelector(selectAllDeploymentsFilters)
  const filterOptions = useSelector(selectAllDeploymentsFilterOptions)

  const onUpdatedFilter = (type) => (value) => {
    dispatch(setAllDeploymentsFilter(type, value))
  }

  const onDetailsClick = (shipCode) => {
    swapView('ship', shipCode)
  }

  return (
    <div className="deployments-container">
      <header className="panel-header">
        <div className="title-row">
          <div className="title">Browse All Deployments</div>
        </div>
        <DeploymentFilters
          filters={filters}
          filterOptions={filterOptions}
          onUpdatedFilter={onUpdatedFilter}
        />
      </header>
      <main>
        {filteredShips.length > 0 ? (
          <ExpandableCardLayout>
            {filteredShips.map((ship, index) => (
              <DeploymentCard
                ship={ship}
                key={index}
                onDetailsClick={onDetailsClick}
              />
            ))}
          </ExpandableCardLayout>
        ) : (
          <h3 className="nothing-found-error">
            No Sailings Found. Please modify your search criteria and try again.
          </h3>
        )}
      </main>
    </div>
  )
}

DeploymentsContainer.propTypes = {
  swapView: func.isRequired,
}

export default DeploymentsContainer
